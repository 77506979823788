'use client'
import iomaLogo from '@/assets/logos/IOMA_UMA.png'
import logo from '@/assets/logo_original.png'
import logoWhite from '@/assets/umawhite.svg'
import parseSubdomain from '@/OldRouter/Providers/utils/parseSubdomain'
import Image from '@/reusableComponents/Image'
import { Spacer } from 'occipital-new'

const ContainerLogo = ({children}) => {
	return (
		<div className='grid place-content-center'>
			{children}
		</div>
	)
}

export const LogoSelector = ({ white }: { white?: boolean }) => {
	const brand = parseSubdomain()
	if (brand === 'IOMA') {
		return (
			 <ContainerLogo>
				<Spacer direction='vertical' value="30px"/>
				<Image src={iomaLogo} alt='' width='164px' center />
				<Spacer direction='vertical' value="10px"/>
			</ContainerLogo>
		)
	}
	return (
		<ContainerLogo>
			{ white ? <Image	className="z-10" src={logoWhite} alt="" width="104px" center /> 
				: 
			 <Image className='aspect-square' src={logo} alt='' width='164px' height='300px' center />
			}
		</ContainerLogo>
	)
}
