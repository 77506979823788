'use client'
import { useEffect } from 'react'

/**
 * 
 * @param options más informacion aquí: https://developers.google.com/tag-platform/tag-manager/web/datalayer
 */
function useDatalayer( options : Record<string, unknown> ){

	useEffect(() => {
		window.dataLayer.push(options) 
	},[options])

}

export default useDatalayer