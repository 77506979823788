function parseSubdomain(){
	let host
	if (typeof window === 'undefined') {
		host = 'umasalud'
	} else {
		host = window.location.host 
	}
	const splittedHost:string[] = host.split('.')
	if(splittedHost[3] === 'umasalud'){
		const brand:string = splittedHost[2].toUpperCase()
		return brand
	}
	if(splittedHost[0] === 'pacientes' && splittedHost[2] === 'umasalud'){
		const brand:string = splittedHost[1].toUpperCase()
		return brand
	}
	return 'NO_SUBDOMAIN'
}

export default parseSubdomain