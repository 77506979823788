/* eslint-disable no-undef */
import Firebase from 'firebase/compat/app'
import {getApp, getApps, initializeApp} from 'firebase/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/performance'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const FirebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
}

export const app = getApps().length? getApp() : initializeApp(FirebaseConfig)
/**
 * @deprecated
 * Por favor ,no inicialices o uses en otro archivo esto, se eliminará el export en el futuro.
 */
export const firebaseInitializeApp = Firebase.initializeApp(FirebaseConfig)

/**
 * @deprecated
 * Por favor, importa sólo {firestore} de este archivo si intentas utilizar nuestra DB,
 * en caso de que necesites otro servicio como el storage, revisa los nuevos
 * métodos de (Firebase 9)[https://firebase.google.com/docs/storage/web/upload-files?hl=es]
 */
function FBConnection() {
	return Firebase
}

/**
 * @deprecated
 * Por favor, importa sólo {firestore} de este archivo si intentas utilizar nuestra DB,
 * en caso de que necesites otro servicio como el storage, revisa los nuevos
 * métodos de (Firebase 9)[https://firebase.google.com/docs/storage/web/upload-files?hl=es]
 * @example
 *
 */
export const db = FBConnection().firestore()

/**
 * Esta instancia de Firestore sólo anda con los métodos de Firebase 9.
 * Se ruega poner bajo services/firebase cualquier llamada a la DB y tiparlo en
 * Typescript, hay muchos ejemplos ahí sencillos de cómo realizarlo.
 * 
 * @example
 * async function getAppointmentByAssignId ( assignationId : string) {
	const appointmentPath : Record<countries,string> = {
		'AR' : 'assignations/online_clinica_medica/bag/',
		'MX' : 'assignations/bag/MX/'
	}
	const appointmentRef = doc(firestore, appointmentPath[process.env.NEXT_PUBLIC_COUNTRY as countries], assignationId) as DocumentReference<IAppointment>
	let appointment : DocumentSnapshot<IAppointment>
	try {
		appointment = await getDoc(appointmentRef)
	} catch(err){
		throw new Error(`${err}`)
	}


	if (appointment){
		return appointment.data()
	}

	return null

}

export default getAppointmentByAssignId
 */

export const auth = getAuth(app)
export const firestore = getFirestore(app)

/** @deprecated please dont */
const fbConnectionlegacy = FBConnection()

/**
 * @deprecated
 * Por favor, importa sólo {firestore} de este archivo si intentas utilizar nuestra DB,
 * en caso de que necesites otro servicio como el storage, revisa los nuevos
 * métodos de (Firebase 9)[https://firebase.google.com/docs/storage/web/upload-files?hl=es]
 */
export default fbConnectionlegacy

export const storage = getStorage(app)
